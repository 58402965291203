<template>
  <tr :class="{ 'channel__row-inactive': !props.item.active }">
    <WiListDataTableActions
      :config="config"
      :props="props"
    ></WiListDataTableActions>
    <td class="justify-center">
      {{props.item.name}}
    </td>
    <td class="justify-center">
      {{props.item.type.name}}
    </td>
    <td class="justify-center">
      {{props.item.description}}
    </td>
    <td class="justify-center">
      <span v-if="props.item.active" class="channels__status channels__status--active">
        Ativo
      </span>
      <span v-else class="channels__status channels__status--inactive">
        Inativo
      </span>
    </td>
    <td class="justify-center" style="padding-top: 4px;">
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" disabled>
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile @click="$WiDialog(wiDialogs.files(props.item))">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>image</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Imagens</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Channels',
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
.channel__row-inactive {
  background: rgb(209, 209, 209);
}
.channels__status {
  border-width: 1px;
  border-style: solid;
  padding: 2px 5px;
}
.channels__status--active {
  color: #eceff1;
  background: rgb(4, 112, 0);
}
.channels__status--inactive {
  color: grey;
  background: #eceff1;
}
</style>